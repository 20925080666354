import React from 'react';
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube, FaPhone } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section footer-logo">
          <img src={`${process.env.PUBLIC_URL}/images/logoo.png`} alt="Company Logo" className="logo" />
          <a href="tel:9259840822" className="contact-button">
          (925) 984-0822

<FaPhone className="arrow-icon" />
          </a>
        </div>
        <div className="footer-section footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#blog">Blog</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section footer-hours">
          <h4>Hours of Operation</h4>
          <ul>
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
              <li key={day}>{day}: 7:00 AM - 8:00 PM</li>
            ))}
          </ul>
        </div>
        <div className="footer-section footer-info">
          <h4>Information</h4>
          <p>(925) 984-0822</p>
          <p>johnsmutny5@gmail.com</p>
          <h4>Follow Us On</h4>
          <div className="social-icons">
            <a href="#"><FaInstagram /></a>
            <a href="#"><FaFacebook /></a>
            <a href="#"><FaLinkedin /></a>
            <a href="#"><FaYoutube /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;