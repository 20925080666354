import React, { useState } from 'react';

const MobileRoofMossRemovalBenefits = () => {
  const benefitsData = [
    {
      title: 'Avoid Roof Replacement',
      details: `The moss on your roof may look PNW picturesque however, it is actually causing severe damage. Moss roots between and under your shingles causing direct damage to the integrity of your roof while also created areas of moisture which leads to leaks and deterioration. The cost of roof cleaning is typically less than 5% of the cost of a new roof.`
    },
    {
      title: 'Prevents New Organic Growth',
      details: `Our roof cleaning process not only removes existing moss and algae but also helps prevent new growth. We apply a protective treatment that continues to work long after we've finished, keeping your roof clean and protecting it from future organic growth.`
    },
    {
      title: 'Safe Procedure',
      details: `Our roof cleaning procedure is designed to be safe for your roof and the environment. We use low-pressure washing techniques and eco-friendly cleaning solutions that effectively remove moss without damaging your shingles or polluting the surrounding area.`
    }
  ];

  const [selectedBenefit, setSelectedBenefit] = useState(null);

  const toggleBenefit = (index) => {
    setSelectedBenefit(selectedBenefit === index ? null : index);
  };

  return (
    <div className="mobile-benefits-container">
      <div className="mobile-content-wrapper">
        <h2 className='mobile-benefits-title'>Benefits Of Our Bellingham <span className="mobile-highlight">Roof Moss Removal</span> Services</h2>
        <div className="mobile-benefits-content">
          {benefitsData.map((benefit, index) => (
            <div key={index} className="mobile-benefit-item">
              <div className="mobile-benefit-question" onClick={() => toggleBenefit(index)}>
                <span className={`mobile-arrow ${selectedBenefit === index ? 'open' : ''}`}>▶</span>
                {benefit.title}
              </div>
              <div className={`mobile-benefit-answer ${selectedBenefit === index ? 'open' : ''}`}>
                <p>{benefit.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

        .mobile-benefits-container {
          background-color: #f0f7ff;
          padding: 60px 20px;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          font-style: normal;
        }
        .mobile-content-wrapper {
          max-width: 1000px;
          margin: 0 auto;
        }
        .mobile-benefits-title {
          color: #1e3a8a;
          font-size: 36px;
          margin-bottom: 40px;
          text-align: center;
          font-weight: bold;
        }
        .mobile-highlight {
          color: #3b82f6;
        }
        .mobile-benefits-content {
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 0 45px -5px rgba(0, 0, 0, .15);
          padding: 30px;
        }
        .mobile-benefit-item {
          margin-bottom: 10px;
          border-bottom: 1px solid #e0e0e0;
        }
        .mobile-benefit-question {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 15px 0;
          font-weight: 600;
          color: #34495e;
        }
        .mobile-arrow {
          margin-right: 15px;
          transition: transform 0.3s ease;
          color: #3498db;
        }
        .mobile-arrow.open {
          transform: rotate(90deg);
        }
        .mobile-benefit-answer {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease, padding 0.3s ease;
          padding: 0 0 0 30px;
          color: #5a6a7a;
        }
        .mobile-benefit-answer.open {
          max-height: 1000px;
          padding: 10px 0 20px 30px;
        }

        @media (min-width: 651px) {
          .mobile-benefits-container {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default MobileRoofMossRemovalBenefits;