import React, { useState, useRef, useEffect } from 'react';
import './BeforeAfterSliderSet.css';

const BeforeAfterSlider = ({ beforeImage, afterImage, alt }) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMove = (event) => {
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newPosition = ((event.clientX - sliderRect.left) / sliderRect.width) * 100;
    setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    const touch = event.touches[0];
    handleMove(touch);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      slider.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <div style={{background:'white', marginBottom:50}} className="before-after-slider" ref={sliderRef} onMouseMove={handleMove}>
      <img className="image-before" src={beforeImage} alt={`Before ${alt}`} />
      <img 
        className="image-after" 
        src={afterImage} 
        alt={`After ${alt}`} 
        style={{ clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)` }}
      />
      <div className="slider-handle" style={{ left: `${sliderPosition}%` }}>
        <div className="slider-line"></div>
        <div className="slider-button"></div>
      </div>
      <span className="label before" style={{ opacity: sliderPosition <= 15 ? 0 : 1 }}>Before</span>
<span className="label after" style={{ opacity: sliderPosition >= 85 ? 0 : 1 }}>After</span>
    </div>
  );
};

const BeforeAfterSliderSet = ({
  sliderBeforePath1, sliderBeforePath2, sliderBeforePath3,
  sliderAfterPath1, sliderAfterPath2, sliderAfterPath3
}) => {
  return (
    <div className="slider-set-container">
      <h1 className='slider-title'>We Can Restore Your Property Instantly</h1>
      <div className="slider-set">
        <BeforeAfterSlider
          beforeImage={sliderBeforePath1 || "/images/after.jpg"}
          afterImage={sliderAfterPath1 || "/images/before.jpg"}
          alt="Roof Restoration"
        />
        <BeforeAfterSlider
          beforeImage={sliderBeforePath2 || "/images/after2.jpg"}
          afterImage={sliderAfterPath2 || "/images/before2.jpg"}
          alt="Roof Restoration"
        />
        <BeforeAfterSlider
          beforeImage={sliderBeforePath3 || "/images/after3.jpg"}
          afterImage={sliderAfterPath3 || "/images/before3.jpg"}
          alt="Roof Restoration"
        />
      </div>
    </div>
  );
};

export default BeforeAfterSliderSet;