import React from 'react';
import './Banner.css'
import testImage from './test.jpg'

const Banner = () => {
  return (
    <div className="banner" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${testImage})`}}>
      <h1 className="banner-title">WE GUARANTEE QUALITY SERVICE!</h1>
      <p className="banner-subtitle">Try Our Professional Services Risk-Free Today</p>
      <button className="banner-button">GET A FAST QUOTE</button>
    </div>
  );
};

export default Banner;