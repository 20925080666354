import React from 'react';
import './Reviews.css';

const reviews = [
  {
    name: 'Brian Smith',
    location: 'Roof Cleaning In Danville',
    review: "Jackson's team goes where eagles fear to fly and made quick work of cleaning our very high and steep 3ply asphalt roof and gutters. Highly recommended roof cleaning service.",
    rating: 5,
  },
  {
    name: 'Michelle Demmert',
    location: 'Roof Cleaning In Alamo',
    review: "Very happy with thoroughness of windows, skylights, deck glass and roof cleaning. I will make them part of my annual maintenance.",
    rating: 5,
  },
  {
    name: 'Aden Idrees',
    location: 'Roof Cleaning In Concord',
    review: "Very happy with thoroughness of windows, skylights, deck glass and roof cleaning. I will make them part of my annual maintenance.",
    rating: 5,
  },
];

const getInitials = (name) => {
  return name.split(' ')[0][0].toUpperCase();
};

const getColorFromName = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16);
  return '#' + '0'.repeat(6 - color.length) + color;
};

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h2 className='review-title' style={{color:'white'}}>What Your Neighbors Have Been Saying About Us</h2>
      <p style={{color:'white'}}>Honest Reviews From Real People</p>
      <div className="reviews">
        {reviews.map((review, index) => (
          <div className="review-card" key={index}>
            <div className="review-content">
              <div>
                <div 
                  className="review-avatar" 
                  style={{ backgroundColor: getColorFromName(review.name) }}
                >
                  {getInitials(review.name)}
                </div>
                <h3>{review.name}</h3>
                <h4>{review.location}</h4>
              </div>
              <p style={{fontStyle:'italic'}}>{review.review}</p>
              <div className="rating">
                {Array.from({ length: 5 }, (v, i) => (
                  <span key={i} className={i < review.rating ? 'star filled' : 'star'}>&#9733;</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;