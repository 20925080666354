import React, { useState, useEffect } from 'react';
import './Quote.css';

const services = [
  { id: 'window', name: 'Window Cleaning' },
  // ... other services
];

const windowCleaningQuestions = [
  { id: 'insideOutPanes', label: 'How Many Window Panes Do You Need Cleaned Inside and Out?' },
  { id: 'exteriorOnlyPanes', label: 'How Many Window Panes Do You Need Cleaned Exterior Only?' },
  { id: 'frenchPanes', label: 'How Many French Window Panes or Doors Do You Need Cleaned?' },
  { id: 'lightFixtures', label: 'How Many Outside Light Fixtures Do You Need Cleaned?' },
  { id: 'chandeliers', label: 'How Many Chandeliers Do You Need Cleaned?' },
  { id: 'mirrors', label: 'How Many Mirrors Do You Need Cleaned?' },
  { id: 'screens', label: 'How Many Screens Do You Need Cleaned?' },
  { id: 'hardWaterStain', label: 'Hard Water Stain Removal Needed?', type: 'select', options: ['No', 'Yes'] }
];

const calculateEstimate = (answers) => {
  // Implement your estimation logic here
  return 50.00; // Placeholder value
};

const Quote = () => {
  const [selectedService, setSelectedService] = useState('window');
  const [formAnswers, setFormAnswers] = useState({});
  const [estimate, setEstimate] = useState(50.00);
  const [contactInfo, setContactInfo] = useState({ name: '', email: '', phone: '', date: '', address: '' });

  useEffect(() => {
    setEstimate(calculateEstimate(formAnswers));
  }, [formAnswers]);

  const handleInputChange = (id, value) => {
    setFormAnswers(prev => ({ ...prev, [id]: value }));
  };

  const handleContactInfoChange = (field, value) => {
    setContactInfo(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted', { service: selectedService, answers: formAnswers, contactInfo, estimate });
  };

  return (
    <div style={{minHeight: '100vh'}}>
      <div className="quote-outer-container">
        <div className="quote-image-header">
          <img src="/images/logo.jpg" alt="Wolf Windows Logo" className="logo" />
          <h1 className="header-title">Window Cleaning Estimate</h1>
        </div>
        <div className="quote-container">
          <div className="quote-content">
            <div className="form-section">
              <h2>Instant Pricing</h2>
              <p>Provide the information needed to get your instant estimate.</p>
              <select
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                className="service-select"
              >
                {services.map(service => (
                  <option key={service.id} value={service.id}>{service.name}</option>
                ))}
              </select>
              {windowCleaningQuestions.map(question => (
                <div key={question.id} className="form-group">
                  <label>{question.label}</label>
                  {question.type === 'select' ? (
                    <select
                      value={formAnswers[question.id] || ''}
                      onChange={(e) => handleInputChange(question.id, e.target.value)}
                    >
                      {question.options.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="number"
                      value={formAnswers[question.id] || ''}
                      onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="estimate-section">
              <div className="estimate-container">
                <h2>Your Current Estimate</h2>
                <p className="estimate-amount">${estimate.toFixed(2)}</p>
              </div>
              <h2>Ready To Have Clean Windows?</h2>
              <p>Please provide your contact information</p>
              <form onSubmit={handleSubmit} className="contact-form">
                <input
                  type="text"
                  placeholder="Full Name"
                  value={contactInfo.name}
                  onChange={(e) => handleContactInfoChange('name', e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={contactInfo.email}
                  onChange={(e) => handleContactInfoChange('email', e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={contactInfo.phone}
                  onChange={(e) => handleContactInfoChange('phone', e.target.value)}
                />
                <input
                  type="date"
                  value={contactInfo.date}
                  onChange={(e) => handleContactInfoChange('date', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={contactInfo.address}
                  onChange={(e) => handleContactInfoChange('address', e.target.value)}
                />
                <button type="submit" className="submit-button">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;