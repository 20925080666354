import React, { useState } from 'react';

const RoofMossRemovalBenefits = ({serviceSection3Title, serviceSection3Button1,
  serviceSection3Button2, serviceSection3Button3,
  ServiceSection3Description1, ServiceSection3Description2,
  ServiceSection3Description3, ServiceSectionSubtitle1,
  ServiceSectionSubtitle2, ServiceSectionSubtitle3, service}) => {
  const benefitsData = [
    {
      title: serviceSection3Button1 || 'Avoid Roof Replacement',
      details: ServiceSection3Description1 || `The moss on your roof may look PNW picturesque however, it is actually causing severe damage. Moss roots between and under your shingles causing direct damage to the integrity of your roof while also created areas of moisture which leads to leaks and deterioration. The cost of roof cleaning is typically less than 5% of the cost of a new roof.`
    },
    {
      title: serviceSection3Button2 || 'Prevents New Organic Growth',
      details: ServiceSection3Description2 || `Our roof cleaning process not only removes existing moss and algae but also helps prevent new growth. We apply a protective treatment that continues to work long after we've finished, keeping your roof clean and protecting it from future organic growth.`
    },
    {
      title: serviceSection3Button3 || 'Safe Procedure',
      details: ServiceSection3Description3 || `Our roof cleaning procedure is designed to be safe for your roof and the environment. We use low-pressure washing techniques and eco-friendly cleaning solutions that effectively remove moss without damaging your shingles or polluting the surrounding area.`
    }
  ];

  const [selectedBenefit, setSelectedBenefit] = useState(benefitsData[0]);

  return (
    <div className="benefits-container">
      <div className="content-wrapper">
        <h2 style={{fontSize:'2.25rem'}}>Benefits Of Our <span className="highlight">{service}</span> Services</h2>
        <div className="benefits-content">
          <div className="benefits-list">
            {benefitsData.map((benefit, index) => (
              <button
                key={index}
                className={`benefit-item ${selectedBenefit.title === benefit.title ? 'active' : ''}`}
                onClick={() => setSelectedBenefit(benefit)}
              >
                {benefit.title}
              </button>
            ))}
          </div>
          <div className="benefit-details">
            <h3>We'll Leave Your Roof Completely Spotless</h3>
            <p>{selectedBenefit.details}</p>
          </div>
        </div>
      </div>
      <style jsx>{`
              @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

        .benefits-container {
          background-color: #f0f7ff;
          padding: 60px 20px;
          font-family: Arial, sans-serif;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          font-style: normal;
        }
        .content-wrapper {
          max-width: 1000px;
          margin: 0 auto;
        }
        h2 {
          color: #1e3a8a;
          font-size: 36px;
          margin-bottom: 40px;
          text-align: center;
          font-weight: bold;
        }
        .highlight {
          color: #3b82f6;
        }
        .benefits-content {
          display: flex;
          position: relative;
        }
        .benefits-list {
          width: 280px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
        }
        .benefit-item {
          background-color: white;
          padding: 15px 20px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          font-size: 16px;
          text-align: left;
          width: 100%;
          font-weight: bold;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        .benefit-item:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .benefit-item:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        .benefit-item:hover, .benefit-item.active {
          background-color: #1e3a8a;
          color: white;
        }
        .benefit-details {
          background-color: white;
          padding: 40px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          margin-left: 260px;
          width: calc(100% - 260px);
        }
        h3 {
          color: #1e3a8a;
          font-size: 24px;
          margin-bottom: 20px;
          font-weight: bold;
        }
        p {
          color: #4b5563;
          line-height: 1.8;
          font-size: 16px;
        }

        @media (max-width: 650px) {
          .benefits-container {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default RoofMossRemovalBenefits;