// src/components/ServiceIntro.js
import React from 'react';
import './ServiceIntro.css';

const ServiceIntro = () => {
  return (
    <div className="service-intro">
      <h2>We Are So Glad You Are Here...</h2>
      <h2>How Can We Best <span className="highlight">Assist You?</span></h2>
      <p>Click On Any Of The Boxes To Learn More About Our Core Services</p>
    </div>
  );
};

export default ServiceIntro;