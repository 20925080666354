import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Nav1.css';
import { FaBars, FaPhone, FaTimes } from 'react-icons/fa';
import { MdAttachMoney } from "react-icons/md";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (servicesDropdownOpen) setServicesDropdownOpen(false);
  };

  const toggleServicesDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setServicesDropdownOpen(!servicesDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setServicesDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
    setServicesDropdownOpen(false);
  }, [location]);

  const serviceItems = [
    { name: 'House & Roof Washing', path: '/house-washing' },
    { name: 'Concrete & Deck Washing', path: '/concrete-washing' },
    { name: 'Gutter Cleaning', path: '/gutter-cleaning' },
    { name: 'Window Cleaning', path: '/window-cleaning' },
    { name: 'Holiday Light Installation', path: '/holiday-lights' }
  ];

  return (
    <nav className={`new-navbar ${isScrolled ? 'scrolled' : ''} ${isOpen ? 'menu-open' : ''}`}>
      <div className="new-navbar-logo">
        <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <img src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="Wolf Windows Logo" />
        </Link>
      </div>
      <div className="new-navbar-scroll-text">
        GET A FAST & <span>FREE QUOTE</span>
      </div>
      <ul className={`new-navbar-menu ${isOpen ? 'open' : ''}`}>
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/about">ABOUT US</Link></li>
        <li className="new-services-dropdown" ref={dropdownRef}>
          <a href="#" onClick={toggleServicesDropdown}>
            SERVICES <span className="dropdown-icon">▼</span>
          </a>
          <ul className={`new-dropdown-menu ${servicesDropdownOpen ? 'open' : ''}`}>
            {serviceItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li><Link to="/">REVIEWS</Link></li>
        {/* <li><Link to="/about">GALLERY</Link></li> */}
      </ul>
      <div className="new-navbar-buttons">
        <Link to="/quote" className="new-quote-button">GET A FAST QUOTE <MdAttachMoney className='icon'/></Link>
        <a href="tel:9259840822" className="new-phone-button">(925) 984-0822 < FaPhone className='icon'/></a>
      </div>
      <div className="new-menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;