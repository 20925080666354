import React from 'react';
import { FaCheckDouble, FaShieldAlt, FaDollarSign, FaLeaf, FaStar, FaUsers, FaUserTie, FaTruck, FaSmile, FaTimes } from 'react-icons/fa';
import './EndOfDay.css';

const EndOfDay = () => {
  return (
    <div className="end-of-day-container">
      <div className="image-container">
        <img src="/images/logo.jpg" alt="End of Day Service" className="service-image" />
      </div>
      <div className="content">
        <h2>At The End Of Day</h2>
        <h3>Here's What You Can <span className="highlight">Count On</span></h3>
        <ul className="feature-list">
          <li><FaCheckDouble /> All Work Warrantied</li>
          <li><FaShieldAlt /> Licensed And Insured</li>
          <li><FaDollarSign /> Living Wage Employer</li>
          <li><FaLeaf /> Eco Friendly Business</li>
          <li><FaStar /> 100'S Of 5 Star Reviews</li>
          <li><FaUsers /> Community Involvement</li>
          <li><FaUserTie /> Highly Trained Technicians</li>
          <li><FaTruck /> State Of The Art Equipment</li>
          <li><FaSmile /> 100% Satisfaction Guarantee</li>
          <li className="crossed-out"><FaTimes /> Splash And Dash Poor Quality Service</li>
        </ul>
      </div>
    </div>
  );
};

export default EndOfDay;