import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';
import { FaPhone } from 'react-icons/fa';
import { MdAttachMoney } from "react-icons/md";
const Hero = ({service,path}) => {
  return (
    <div className="hero">
        <img src={path ? path : `/images/wolfbg4.png`} alt="Window Cleaning" className="hero-image" />
      <div className="hero-overlay">
        <h1 className="hero-title">{service ? `EAST BAY'S TOP RATED ${service}` : "EAST BAY'S HIGHEST RATED AND MOST REVIEWED EXTERIOR CLEANERS"}</h1>
        <p style={{color:'white'}} className="hero-subtitle">We'll INSTANTLY Add Value To Your Home!</p>
        <div className="button-container">
          <Link to="/quote" className="quotee-button">
            GET A FAST QUOTE <MdAttachMoney className='icon' />
          </Link>
          <a href="tel:9259840822" className="phone-button">(925) 984-0822 <FaPhone className="icon"></FaPhone></a>
        </div>
        {/* <div className="award-container">
          <img src="/images/seal.png" alt="2022 Award" className="award-image" />
          <img src="/images/seal.png" alt="Seal Award" className="award-image" />
        </div> */}
      </div>
    </div>
  );
};

export default Hero;