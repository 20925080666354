import React from 'react';
import './AboutUs.css'

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="about-us-section">
        <div className="about-us-content">
          <div className="about-us-text">
            <h2 className="about-us-title">About Us</h2>
            <p>
              Our CEO started Wolf Windows as twenty-four old visionary
              with very little resources. Hard work, honesty, and reliability quickly helped us
              scale our company to be one of the largest and top-rated exterior cleaning
              companies in the area. Mission Statement: Here at Wolf Windows
              we are committed to providing excellence for our clients, employees, and
              community alike.
            </p>
          </div>
          <div className="about-us-image-placeholder">
            <span>Image coming soon</span>
          </div>
        </div>
      </section>

      <section className="about-us-section">
        <div className="about-us-content">
          <div className="about-us-text">
            <h2 className="about-us-title">Highly Trained and Highly Paid Technicians.</h2>
            <p>
              All of our technicians go through two weeks of training that covers everything
              from customer service to technical washing. Even after that they are continually
              mentored by their lead technician until they are ready to run their own crew. We
              pay our technicians above industry standard because we believe happy technicians
              will take care of your home like it is their own. Our crews text you when they are
              on the way and show up in uniforms and on time so your day isn't interrupted by
              our service. At the end of every job our lead technician will walk you around the
              house to show you the great job they did and/ or point out any items of concern
              you should know about. We always strive for greatness in our work in hopes you
              will have us the following year to clean your home again.
            </p>
          </div>
          <div className="about-us-image-placeholder">
            <span>Image coming soon</span>
          </div>
        </div>
      </section>

      <section className="about-us-section">
        <div className="about-us-content">
          <div className="about-us-text">
            <h2 className="about-us-title">Incredible Customer Service</h2>
            <p>
              We start taking care of you from the moment you contact us. When you call Wolf
              Windows, a real person picks up the phone who is there to
              provide you with seamless scheduling, answers to your questions, or anything else
              you may need. Our technicians provide the best possible service you can ask for
              and all of our work is guaranteed. No wonder we are rated 5 stars across so many
              different platforms. When people want a reliable, professional, and on time
              service, they call us.
            </p>
          </div>
          <div className="about-us-image-placeholder">
            <span>Image coming soon</span>
          </div>
        </div>
      </section>

      <section className="about-us-section">
        <div className="about-us-content">
          <div className="about-us-text">
            <h2 className="about-us-title">Safe Cleaning Practices</h2>
            <p>
              Safety is our biggest priority at Wolf Windows and that's why we
              use the latest and greatest equipment and techniques to take care of your home.
              Our water fed poles allow crews to do routine window cleaning from the ground
              without the use of a ladder! Our Soft Wash methods are the most effective
              cleaning procedures to date and our safe for your home and family. In-fact we don't
              even need to get on your roof to clean it most of the time. We use a biodegradable
              cleaning solution so you can have peace of mind that service being done on your
              home is environmentally friendly.
            </p>
          </div>
          <div className="about-us-image-placeholder">
            <span>Image coming soon</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;