import React from 'react';
import { Link } from 'react-router-dom';
import { MdAttachMoney } from "react-icons/md";

const ServiceSection4 = ({serviceSection4Title, serviceSection4Description}) => {
  return (
    <div className="service-section-4-wrapper">
      <div className="service-section-4">
        <div className="service-4-image-wrapper">
          <img src="/images/logo.jpg" alt="House washing service" className="service-4-image" />
        </div>
        <div className="service-4-content">
        <h2 className="service-4-title">{serviceSection4Title ? serviceSection4Title : 'Increase your curb appeal instantly with our low pressure house washing'}</h2>
        <p className="service-4-description">{serviceSection4Description ? serviceSection4Description : 'Soft washing is a safe alternative to pressure washing for all delicate surfaces of your property. We mix a range of different washing solutions for all of your exterior surfaces. It will wash away growth, dirt, dust, and salt spray off your siding safely and in a fraction of the time pressure washing takes! Our soft washing detergents are 100% biodegradable, safe for your home, and uses less water than regular pressure washing.'}</p>
          <div className="service-4-button-container">
            <Link to='/quote' className="service-4-cta-button">GET A FAST QUOTE <MdAttachMoney className='service-4-icon'/></Link>
          </div>
        </div>
      </div>
      <style jsx>{`
        .service-section-4-wrapper {
          background-color: #f8f9fa;
          padding: 40px 0;
          width: 100%;
        }
        .service-section-4 {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          overflow: hidden;
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }
        .service-4-image-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          order: -1;
        }
        .service-4-image {
          max-width: 100%;
          height: auto;
          border: 2px solid #1e3a8a;
          border-radius: 15px;
          object-fit: cover;
        }
        .service-4-content {
          width: 100%;
        }
        .service-4-title {
          color: #1e3a8a;
          font-size: 24px;
          margin-bottom: 15px;
          text-align: center;
        }
        .service-4-description {
          color: #4b5563;
          margin-bottom: 20px;
          text-align: left;
        }
        .service-4-button-container {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .service-4-cta-button {
          background-color: rgba(80, 219, 250, 0.8);
          color: white;
          padding: 0.8rem 1.5rem;
          border: 2px solid rgba(80, 219, 250, 0.8);
          border-radius: 45px;
          cursor: pointer;
          font-weight: 600;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: clamp(0.9rem, 2vw, 1.2rem);
          transition: all 0.3s ease;
          position: relative;
          overflow: hidden;
          z-index: 1;
        }
        .service-4-cta-button::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: lightgray;
          transition: left 0.3s ease;
          z-index: -1;
        }
        .service-4-cta-button:hover::before {
          left: 0;
        }
        .service-4-cta-button:hover {
          color: white;
          background-color: rgba(80, 219, 250, 1);
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        .service-4-icon {
          margin-left: 5px;
        }

        @media (min-width: 768px) {
          .service-section-4 {
            flex-direction: row;
            padding: 50px;
          }
          .service-4-image-wrapper {
            width: 45%;
            padding-left: 30px;
            margin-bottom: 0;
            order: 2;
          }
          .service-4-content {
            width: 55%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            order: 1;
          }
          .service-4-title {
            text-align: left;
            font-size: 28px;
            margin-bottom: 20px;
          }
          .service-4-description {
            font-size: 16px;
            line-height: 1.6;
          }
          .service-4-button-container {
            justify-content: flex-start;
          }
          .service-4-cta-button {
            width: auto;
          }
        }

        @media (min-width: 1200px) {
          .service-section-4 {
            padding: 60px;
          }
          .service-4-title {
            font-size: 32px;
          }
          .service-4-description {
            font-size: 18px;
          }
        }
      `}</style>
    </div>
  );
};

export default ServiceSection4;