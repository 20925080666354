import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';

const wolfServices = [
  {
    title: 'Concrete & Deck Washing',
    image: `${process.env.PUBLIC_URL}/images/serviceOne.png`,
    description: 'Perfectly maintain your patio and walkway surfaces, giving your property a refreshed, new appearance.',
    path: '/concrete-washing'
  },
  {
    title: 'House & Roof Washing',
    image: `${process.env.PUBLIC_URL}/images/serviceTwo.png`,
    description: 'Soft washing offers a safe and effective alternative to pressure washing, perfect for all delicate surfaces of your property.',
    path: '/house-washing'
  },
  {
    title: 'Gutter Cleaning',
    image: `${process.env.PUBLIC_URL}/images/serviceThree.png`,
    description: 'Roof maintenance is a critical, often overlooked task. Regular roof washing ensures optimal roof longevity.',
    path: '/gutter-cleaning'
  },
  {
    title: 'Window Cleaning',
    image: `${process.env.PUBLIC_URL}/images/windowss.png`,
    description: `Our eco-friendly window washing service enhances your property's curb appeal, making your windows shine again.`,
    path: '/window-cleaning'
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="wolf-services-grid">
      {wolfServices.map((service, index) => (
        <div 
          className="wolf-service-item" 
          key={index} 
          onClick={() => handleCardClick(service.path)}
        >
          <img src={service.image} alt={service.title} className="wolf-service-img" />
          <h3 className="wolf-service-name">{service.title}</h3>
          <div className="wolf-service-details">
            <p className="wolf-service-description">{service.description}</p>
            <button className="wolf-service-btn">Learn More</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Services;