import React from 'react'
import Hero from '../components/Hero'
import ServiceIntro from '../components/ServiceIntro'
import FeaturedService from '../components/FeaturedService'
import Services from '../components/Services'
import Reviews from '../components/Reviews'
import AboutUs from '../components/AboutUs'
import Banner from '../components/Banner'
import EndOfDay from '../components/EndOfDay'
import CheckUs from '../components/CheckUs'
import FAQ from '../components/FAQ'
import BeforeAfterSlider from '../components/BeforeAfterSliderSet'
import BeforeAfterSliderSet from '../components/BeforeAfterSliderSet'

const Home = () => {
  return (
    <div>
        <Hero/>
        <ServiceIntro/>
        <Services />
        <AboutUs/>
        <Banner/>
        <EndOfDay/>
        <CheckUs/>
        <Reviews />
        <FeaturedService/>
        <div style={{background:'white'}}>
          <BeforeAfterSliderSet/>
        </div>

        
        <FAQ/>
    </div>
  )
}

export default Home