const faqData = [
    {
      question: "What Makes Wolf Windows Different?",
      answer: "Our commitment to quality and customer satisfaction sets us apart. We use eco-friendly cleaning solutions and state-of-the-art equipment to ensure the best results for our customers."
    },
    {
      question: "What Areas Do You Service?",
      answer: "We service the greater Mt. Baker area and surrounding communities, including Bellingham, Ferndale, Lynden, and Blaine."
    },
    {
      question: "Are You Licensed, Bonded, And Insured?",
      answer: "Yes, we are fully licensed, bonded, and insured for your peace of mind. We take all necessary precautions to ensure the safety of our staff and your property."
    },
    {
      question: "Do You Have Any References?",
      answer: "We have numerous satisfied customers who are happy to provide references. Please contact us for a list of recent clients you can speak with about our services."
    },
    {
      question: "How Can I Get A Quote?",
      answer: "You can get a quote by calling our office, filling out the contact form on our website, or sending us an email. We'll typically respond within 24 hours with a detailed estimate."
    },
    {
      question: "Do You Charge By The Hour Or By The Job?",
      answer: "We typically charge by the job rather than by the hour. This allows us to provide you with an accurate quote upfront, with no surprises at the end of the service."
    },
    {
      question: "Do You Have A Minimum?",
      answer: "Yes, we have a minimum charge to cover our travel and setup costs. The minimum varies depending on your location. Please contact us for specific details."
    },
    {
      question: "Do You Accept Credit Cards?",
      answer: "Yes, we accept all major credit cards for your convenience. We also accept cash and checks."
    },
    {
      question: "Do You Offer Discounts For Re-Occurring Service?",
      answer: "Yes, we offer discounts for customers who schedule regular, recurring services. The discount amount depends on the frequency of service. Please ask for details when you request a quote."
    }
  ];
  
  export default faqData;