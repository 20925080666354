import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturedService.css';
import { MdAttachMoney } from "react-icons/md";

const FeaturedService = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="fs-container">
      <div className="fs-images-wrapper">
        <img src={`${process.env.PUBLIC_URL}/images/windowss.png`} alt="Window cleaning" className="fs-main-image" />
        <div className="fs-small-images">
          <img src={`${process.env.PUBLIC_URL}/images/service7.png`} alt="Roof cleaning" className="fs-small-image" />
          <img src={`${process.env.PUBLIC_URL}/images/service3.png`} alt="Pressure washing" className="fs-small-image" />
        </div>
      </div>
      <div className="fs-text-wrapper">
        <h4 className="fs-subtitle">TOP RATED EXTERIOR CLEANING IN THE EAST BAY AREA</h4>
        <h2 className="fs-title">Dirty Home? Don't Have Time? We Would Love To <span className="fs-highlight">Help You!</span></h2>
        <p className="fs-description">Maintaining your home's exterior surfaces is quite challenging. Most homeowners lack the time or the necessary tools to safely clean their entire home. That's where we can help. Wolf Windows utilizes advanced equipment, eco-friendly solutions, highly skilled technicians, and safe procedures to ensure your home stays in prime condition.</p>
        <p className="fs-description">As East Bay's Top Rated Exterior Cleaning Company, our goal is to provide maximum value while delivering an exceptional experience. Hiring an exterior cleaning company should be a breeze. That's why we have been setting new standards for service businesses since 2018, continually delighting our clients with smooth operations. It's no surprise that we have serviced over 2,000 satisfied homes in the past five years.</p>
        <Link to="/quote" className="fs-quote-button" onClick={handleClick}>GET A FAST QUOTE <MdAttachMoney className='fs-icon'/></Link>
      </div>
    </div>
  );
};

export default FeaturedService;