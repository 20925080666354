import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Nav1';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Home from './pages/Home';
import Service from './components/Service';
import Quote from './components/Quote';

function App() {

const windowHeroPath = '/images/windowHero.jpg'
const windowBefore1 ='/images/windowbefore.jpg'
const windowBefore2 = '/images/windowbefore2.jpg'
const windowBefore3 = '/images/windowbefore3.jpg'
const windowAfter1 = '/images/windowafter.jpg'
const windowAfter2 = '/images/windowafter2.jpg'
const windowAfter3 = '/images/windowafter3.jpg'
const windowSection = '/images/windowsection.jpg'



  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/house-washing" element={<Service service= 'House & Roof Washing' />} />
          <Route path="/concrete-washing" element={<Service service= 'Concrete & Deck Washing' />} />
          <Route path="/gutter-cleaning" element={<Service service= 'Gutter Cleaning' />} />
          <Route path="/window-cleaning" element={
            <Service 
            service= 'Window Cleaning' 
            heroPath = {windowHeroPath}
            sliderBeforePath1 = {windowBefore1}
            sliderBeforePath2 = {windowBefore2}
            sliderBeforePath3 = {windowBefore3}
            sliderAfterPath1 = {windowAfter1}
            sliderAfterPath2 = {windowAfter2}
            sliderAfterPath3 = {windowAfter3}
            serviceSectionPath = {windowSection}
            serviceSection2Description = "We are EastBay's most trusted window cleaning company. With our eco-friendly window washing service, we can instantly boost your property’s curb appeal and make your windows sparkle again. We would love to help you with your next window washing project. We are versatile in our techniques to ensure the best result and adapt our work to best suit your property."
            serviceSection2Title = "Get the perfect view you deserve with crystal clear window cleaning"
            serviceSection4Description = "Our highly trained technicians are trained in various methods of window cleaning to ensure a spotless result. We can clean french panes, windows with hard water stains, sap, and even glass coated in paint. We are always prepared for anything, and will never leave you with a window that is “Too hard to reach”"
            serviceSection4Title = "Best Window Cleaning Service in the East Bay Area"
            serviceSection3Title = "Benefits Of Our Window Cleaning Services"
            serviceSection3Button1 = "Enjoy a Clean View"
            serviceSection3Button2 = "Maintain a Healthy Home"
            serviceSection3Button3 = "Safe Procedure"
            ServiceSection3Description1 = "Make the most out of your home with professionally cleaned windows. Our service will bring more natural light into your home and give you a view you didn’t even know you had. All of our clients are pleased when we get done cleaning their windows and just can’t wait to get back on our schedule."
            ServiceSection3Description2 = "Your window screens can be seen as the air filters to your home. Screens collected dust and other soils quickly during all seasons. If you open your window and have dirty screens, this means you can be letting unhealthy air into your home. Screen cleaning a regular basis means your homes natural air filters and working properly and keeping you and your family healthy."
            ServiceSection3Description3 = "The majority of our window cleaning jobs can be safely from the ground with the help of our water fed pole systems. These high mod carbon fiber poles are connected to filters that pump pure water thru the brush head and onto your window. This means no streaks from soap/squeegees and you have peace of mind knowing zero ladders are being put against your home."
            ServiceSectionSubtitle1 = "Making Your Window Crystal Clear"
            ServiceSectionSubtitle2 = "Healthy Home, Healthy Life"
            ServiceSectionSubtitle3 = "Exceptional Service And Professional Approach"
            />} />
          <Route path="/holiday-lights" element={<Service service= 'Holiday Light Installation' />} />
          <Route path="/quote" element={<Quote />} />


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;