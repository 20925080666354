import React from 'react';
import ServiceSection1 from './ServiceSection1';
import ServiceSection2 from './ServiceSection2';
import ServiceSection3 from './ServiceSection3';
import ServiceSection4 from './ServiceSection4';
import Hero from './Hero';
import BeforeAfterSliderSet from './BeforeAfterSliderSet';
import ServiceSection3mobile from './ServiceSection3mobile';
import CoreServices from './CoreServices';
import CheckUs from './CheckUs';

const Service = ({service, heroPath, sliderBeforePath1,
   sliderBeforePath2, sliderBeforePath3, sliderAfterPath1,
    sliderAfterPath2, sliderAfterPath3, serviceSectionPath,
    serviceSection2Description, serviceSection2Title,
    serviceSection4Description, serviceSection4Title,
    serviceSection3Title, serviceSection3Button1,
    serviceSection3Button2, serviceSection3Button3,
    ServiceSection3Description1, ServiceSection3Description2,
    ServiceSection3Description3, ServiceSectionSubtitle1,
    ServiceSectionSubtitle2, ServiceSectionSubtitle3
  }) => {
  const services = [
    { id: 1, title: 'Service 1' },
    { id: 2, title: 'Service 2' },
    { id: 3, title: 'Service 3' },
  ];

  return (
    <div style={{marginTop:100}} >
      <Hero service={service} path = {heroPath}/>
      <CheckUs/>
     {/* <ServiceSection1 service = {service}/> */}
     <div style={{background:'white'}}>
     <BeforeAfterSliderSet sliderAfterPath1={sliderAfterPath1}
                           sliderAfterPath2={sliderAfterPath2} 
                           sliderAfterPath3={sliderAfterPath3}
                           sliderBeforePath1={sliderBeforePath1}
                           sliderBeforePath2={sliderBeforePath2}
                           sliderBeforePath3={sliderBeforePath3}
      /></div>
     <ServiceSection2 serviceSectionPath = {serviceSectionPath} 
     serviceSection2Title={serviceSection2Title}
     serviceSection2Description={serviceSection2Description}

     />
     <ServiceSection3 
       serviceSection3Title={serviceSection3Title}
       serviceSection3Button1={serviceSection3Button1}
       serviceSection3Button2={serviceSection3Button2}
       serviceSection3Button3={serviceSection3Button3}
       ServiceSection3Description1={ServiceSection3Description1}
       ServiceSection3Description2={ServiceSection3Description2}
       ServiceSection3Description3={ServiceSection3Description3}
       ServiceSectionSubtitle1={ServiceSectionSubtitle1}
       ServiceSectionSubtitle2={ServiceSectionSubtitle2}
       ServiceSectionSubtitle3={ServiceSectionSubtitle3}
       service={service}
     />
     {/* <ServiceSection3  /> */}
     <ServiceSection3mobile/>
     <ServiceSection4 serviceSection4Title = {serviceSection4Title} serviceSection4Description = {serviceSection4Description}/>
     <CoreServices />
    </div>
  );
};

export default Service;