import React from 'react';
import { Link } from 'react-router-dom';

const CoreServices = () => {
  const services = [
    { imgSrc: '/images/pressure.png', label: 'Concrete & Deck Washing' },
    { imgSrc: '/images/housewashing.png', label: 'House & Roof Washing' },
    // { imgSrc: '/images/roofclean.png', label: 'Roof Moss Removal' },
    { imgSrc: '/images/windowclean.png', label: 'Window Cleaning' },
    { imgSrc: '/images/gutter.png', label: 'Gutter Cleaning' },
    { imgSrc: '/images/lights.png', label: 'Holiday Lighting' },
  ];

  return (
    <div className="core-services-container">
      <h2 style={{textAlign:'center'}}>Our Core Services</h2>
      <div className="core-services-list">
        {services.map((service, index) => (
          <div key={index} className="core-service-item">
            <div className="core-service-icon">
              <img src={service.imgSrc} alt={service.label} className="core-service-image" />
            </div>
            <div className="core-service-label">{service.label}</div>
          </div>
        ))}
      </div>
      <style jsx>{`
        .core-services-container {
          text-align: center;
          padding: 50px 20px;
          background-color: #f8fafc;
        }
        h2 {
          font-size: 28px;
          color: #1e3a8a;
          margin-bottom: 40px;
        }
        .core-services-list {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
        }
        .core-service-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 150px;
          margin-bottom: 20px;
        }
        .core-service-icon {
          margin-bottom: 10px;
        }
        .core-service-image {
          max-width: 100%;
          height: auto;
          filter: grayscale(100%) brightness(0);
        }
        .core-service-label {
          font-size: 16px;
          color: #1e3a8a;
        }
      `}</style>
    </div>
  );
};

export default CoreServices;