import React, { useState } from 'react';
import faqData from './faqData';
import './FAQ.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>▶</span>
        {question}
      </div>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>{answer}</div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="faq-outer-container">
         <h1 className='faq-title' style={{textAlign:'center'}}>Frequently Asked Questions</h1>
      <div className="faq-container">
       
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;